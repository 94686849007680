import { firestore } from '@/plugins/firestore'

const MESSAGE_COLLECTION_NAME =
  process.env.ctiEnv === 'production' ? 'messages_navi' : 'messages_navi_dev'
const messageRef = firestore.collection(MESSAGE_COLLECTION_NAME)

export const state = () => ({})

export const getters = {}

export const actions = {
  getNotifications: ({ rootState }) => {
    if (!rootState.auth.user) {
      return Promise.reject(new Error('Unauthorized'))
    }
    return messageRef
      .where('to', 'array-contains', 'user' + rootState.auth.user.id)
      .orderBy('created_at', 'desc')
      .get()
      .then((qs) => {
        return qs.docs
          .map((doc) => (doc.exists ? doc.data() : false))
          .filter((d) => d)
      })
  },
  getNews: ({ rootState }) => {
    if (!rootState.auth.user) {
      return Promise.reject(new Error('Unauthorized'))
    }
    return messageRef
      .where('to', 'array-contains-any', [
        'user_all',
        'user' + rootState.auth.user.id
      ])
      .orderBy('created_at', 'desc')
      .get()
      .then((qs) => {
        return qs.docs
          .map((doc) => (doc.exists ? doc.data() : false))
          .filter((d) => d)
      })
  }
}

export const mutations = {}
